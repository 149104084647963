/*** MAIN ***/
export const ABOUT = "/about/"
export const CONTACT = "/contact/"
export const ILLUSTRATIONS = "/illustrations/"
export const PROJECTS = "/"
export const WORK = "/work/"

/*** PROJECTS ***/
export const CHATBOT = "/projects/chatbot/"
export const DIEBYDYE = "/projects/die-by-dye/"
export const GDK = "/projects/graphics-design/"
export const IMAGEREP = "/projects/image-reproduction/"
export const IMAGETECH = "/projects/image-technology/"
export const INFOVIZ = "/projects/information-visualization/"
export const NETWORKSECURITY = "/projects/keylogger/"
export const OPENSPACE = "/projects/openspace/"
export const PORTFOLIO = "/projects/portfolio/"
export const POSTIT = "/projects/post-it/"
export const PROCEDURAL = "/projects/procedural-planet/"
export const SIMULATION = "/projects/roller-coaster-simulation/"
export const TETRIS = "/projects/tetris/"
export const VRSTRANDED = "/projects/vr-stranded/"

/*** WORK ***/
export const ERICSSON = "/work/ericsson/"
export const METTE = "/work/mette/"
export const MTD = "/work/media-technology-days/"
export const WKIT = "/work/we-know-it/"

/*** WORK ***/
export const BLOOM = "/illustrations/bloom/"
export const BURST = "/illustrations/burst/"
export const JELLYFISH = "/illustrations/jellyfish/"
export const SKULL = "/illustrations/skull/"
export const WET = "/illustrations/wet/"
