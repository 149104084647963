/*** REACT ***/
import React from "react";

/*** FONTAWESOME ***/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

/*** COMPONENTS ***/
import "../styles/navigation.css";

function openGithub() {
  window.open("https://github.com/johannagranstroem", "_blank");
}
function openLinkedIn() {
  window.open("https://www.linkedin.com/in/johannagranstrom/", "_blank");
}
function openInstagram() {
  window.open("https://www.instagram.com/johannagranstrom/", "_blank");
}

const Footer = () => {
  return (
    <div>
      <div className="navbar-bottom">
        <li class="navbar-bottom-horizontal-items">
          <a href="/" class="icon-link-style" onClick={() => openGithub()}>
            <div style={{ fontSize: "22px" }}>
              <FontAwesomeIcon icon={faGithub} size="lg" />
            </div>
          </a>
        </li>
        <li class="navbar-bottom-horizontal-items">
          <a href="/" class="icon-link-style" onClick={() => openLinkedIn()}>
            <div style={{ fontSize: "22px" }}>
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </div>
          </a>
        </li>
        <li class="navbar-bottom-horizontal-items">
          <a href="/" class="icon-link-style" onClick={() => openInstagram()}>
            <div style={{ fontSize: "22px" }}>
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </div>
          </a>
        </li>
        <li class="navbar-bottom-horizontal-items">
          <a href="mailto:johanna.granstrom@gmail.com">
            <a href="/" class="icon-link-style">
              <div style={{ fontSize: "19px" }}>
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
              </div>
            </a>
          </a>
        </li>
      </div>
      <div class="navbar-footer-bottom-text">
        <p>
          © Developed by Johanna Granström {new Date().getFullYear()}, Built
          with {` `}{" "}
          <a
            class="navigation-link-style-default"
            href="https://www.gatsbyjs.com"
          >
            Gatsby
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
