import React from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

/*** FONTAWESOME ***/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

/*** COMPONENTS ***/
import * as ROUTES from "../constants/routes";
import "../styles/navigation.css";

function openGithub() {
  window.open("https://github.com/johannagranstroem", "_blank");
}
function openLinkedIn() {
  window.open("https://www.linkedin.com/in/johannagranstrom/", "_blank");
}
function openInstagram() {
  window.open("https://www.instagram.com/johannagranstrom/", "_blank");
}

const Navigation = () => {
  const location = useLocation();

  return (
    <div>
      <div className="navbar-top">
        <li>
          <h3 className="header">
            <Link
              className={
                location.pathname === ROUTES.PROJECTS
                  ? "navigation-link-style-active"
                  : "navigation-link-style-default"
              }
              to={ROUTES.PROJECTS}
            >
              PROJECTS
            </Link>
          </h3>
        </li>
        <li>
          <h3>
            <Link
              className={
                location.pathname === ROUTES.WORK
                  ? "navigation-link-style-active"
                  : "navigation-link-style-default"
              }
              to={ROUTES.WORK}
            >
              WORK
            </Link>
          </h3>
        </li>
        <li>
          <h3>
            <Link
              className={
                location.pathname === ROUTES.ILLUSTRATIONS
                  ? "navigation-link-style-active"
                  : "navigation-link-style-default"
              }
              to={ROUTES.ILLUSTRATIONS}
            >
              ILLUSTRATIONS
            </Link>
          </h3>
        </li>
        <li>
          <h3>
            <Link
              className={
                location.pathname === ROUTES.ABOUT
                  ? "navigation-link-style-active"
                  : "navigation-link-style-default"
              }
              to={ROUTES.ABOUT}
            >
              ABOUT
            </Link>
          </h3>
        </li>
        <li>
          <h3>
            <Link
              action="mailto:johanna.granstrom@gmail.com"
              className={
                location.pathname === ROUTES.CONTACT
                  ? "navigation-link-style-active"
                  : "navigation-link-style-default"
              }
              to={ROUTES.CONTACT}
            >
              SAY HI
            </Link>
          </h3>
        </li>
      </div>
      <div className="navbar-left">
        <li className="list-padding">
          <i class="icon-link-style" onClick={() => openGithub()}>
            <div style={{ fontSize: "22px" }}>
              <FontAwesomeIcon icon={faGithub} size="lg" />
            </div>
          </i>
        </li>
        <li className="list-padding">
          <i class="icon-link-style" onClick={() => openLinkedIn()}>
            <div style={{ fontSize: "22px" }}>
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </div>
          </i>
        </li>
        <li className="list-padding">
          <i class="icon-link-style" onClick={() => openInstagram()}>
            <div style={{ fontSize: "22px" }}>
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </div>
          </i>
        </li>
        <li className="list-padding">
          <a href="mailto:johanna.granstrom@gmail.com">
            <i class="icon-link-style">
              <div style={{ fontSize: "19px" }}>
                <FontAwesomeIcon icon={faEnvelope} size="lg" />
              </div>
            </i>
          </a>
        </li>
      </div>
    </div>
  );
};

export default Navigation;
